@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#assetManagerPopup{
  display: none !important;
}


body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
#webgi-canvas-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
}

.ui {
  background-color: white;
  padding: 15px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}
button {
  margin: 5px;
}

.title {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
}

.circle {
  text-align:center;
  width: 29px;
  height: 29px;
  line-height: 25px;
  background-color: white;
  color: black;
  border-radius: 50%;
  border: 1px solid black;
  cursor: pointer;
  -moz-background-clip: padding-box;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.stepWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.stepBlock{
  text-align: center;
}

.stepBlock .circleWrapper {
  padding: 0px 70px;
  position: relative;
}

.selected .circle {
  color: white;
  background-color: black;
}

.stepBlock:not(:last-child) > .circleWrapper::after {
  content: '';
  width: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin: auto;
  z-index: -1;
}

.stepBlock:not(:last-child) > .circleWrapper::after {
  background-color: black;
  height: 2px;
}

.stepBlock:not(:last-child).selected > .circleWrapper::after, .stepBlock:not(:last-child).selected ~ .stepBlock:not(:last-child) > .circleWrapper::after {
  height: 1px;
  opacity: 0.2;
  background-color: rgb(109, 107, 107);
}

.primaryButton {
  padding: 10px 25px;
  background-color: green;
  border: none;
  margin: 10px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.primaryButton:disabled {
  opacity: 0.5;
}

#assetManagerLoadingScreen{
  display: none;
}
